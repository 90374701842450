import React, { useEffect, useState } from "react"
import {
  ArrowLeftIcon,
  ArrowRightOnRectangleIcon,
  BellIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  LockClosedIcon,
  UserCircleIcon,
  BellAlertIcon,
  Bars3Icon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline"
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom"
import { useAuthStore } from "stores"

import { useGet } from "utils/useFetch"
import { ArrowRightIcon } from "@heroicons/react/24/solid"
import { Badge, Dropdown, List } from "antd"
import ImageComponent from "components/custom/image"
import { P2 } from "components/typography/P2"
import { throttle } from "utils/throttle"
import { TextLink } from "components/links/TextLink"
import { mixPanelEvent } from "../mix-panel/MixPanel"
import { isPadeDomain } from "utils/utils"
import { ViewNotificationDetail } from "pages/notifications/modals/ViewNotificationDetail"

const UserProfile: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
  startTourHandler: () => void
}> = ({ isSidebarOpen, toggleSidebar, startTourHandler }) => {
  const navigate = useNavigate()
  const { signOut, user, loginSwitch } = useAuthStore()
  // const [toSwitch, toggleToSwitch] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [itemToView, setItemToView] = useState<any>("")
  const [itemIsRead, setItemIsRead] = useState<boolean>(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  useEffect(() => {
    setOpenNotification(false)
  }, [])

  const handleSignout = () => {
    signOut()
  }

  // const handleSwitch = async () => {
  //   toggleToSwitch(true)

  //   const userId = localStorage.getItem("bgr")
  //   const response = await loginSwitch(userId || "")
  //   if (response.status === "Success") {
  //     const authToken = response.access_token
  //     const encodedAuthToken = encodeURIComponent(authToken)
  //     const encodedUserId = encodeURIComponent(userId || "")

  //     const redirectUrl =
  //       "https://portal.paidhr.com/account/switch?token=" +
  //       encodedAuthToken +
  //       "&code=" +
  //       encodedUserId
  //     window.location.href = redirectUrl
  //   } else {
  //     toggleToSwitch(false)
  //     // navigate("/auth/login")
  //   }
  // }

  const { data, isLoading, refetch } = useGet<any>({
    url: "/employee/notifications/select",
  })

  const unreadNotifications: any[] = data?.data?.filter(
    (it: any) => it?.is_read !== "True"
  )

  const firstFourItems =
    unreadNotifications?.filter((_, index) => index <= 4) || []

  const handleCloseDropdown = () => {
    setDropdownOpen(false)
  }

  return (
    <div className="relative h-auto font-avenir">
      {/* {toSwitch ? (
        <>
          {createPortal(
            <div className=" flex  items-center justify-center py-10 bg-a11y/50 h-screen w-screen fixed inset-0 z-[9999999] ">
              <Spin size="large" />
            </div>,
            document.body
          )}
        </>
      ) : null} */}

      <div className="flex items-center gap-4 md:gap-6">
        <Dropdown
          trigger={["click"]}
          open={openNotification}
          onOpenChange={() => {
            setOpenNotification(!openNotification)
          }}
          dropdownRender={(menu) => (
            <div className="bg-white rounded-lg drop-shadow-2xl  border py-4 lg:w-[300px]">
              <div className="px-4 font-semibold text-neutral700 font-avenir ">
                Notifications
              </div>
              <hr className="my-2 text-neutral50" />
              {data?.data?.filter((it: any) => it?.is_read !== "True")?.length >
              0 ? (
                <div className="w-full px-2 flex flex-col gap-1 ">
                  <List
                    size="small"
                    bordered={false}
                    dataSource={firstFourItems}
                    rowKey={(it) => it.id}
                    renderItem={(item: any) => (
                      <List.Item>
                        <div className="flex gap-2 justify-between my-1 items-center cursor-pointer w-full">
                          <div
                            className="text-ellipsis max-w-full overflow-hidden"
                            // onClick={() => {
                            //   setOpenNotification(false)
                            //   setItemIsRead(false)
                            //   setItemToView(item)
                            // }}
                          >
                            {item?.subject}
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />

                  <hr className="my-2 text-neutral50" />

                  <Link
                    to="notifications"
                    onClick={() => {
                      setOpenNotification(false)
                    }}
                    className="w-full flex gap-2 items-center px-1 mx-auto justify-center  text-neutral500 hover:text-primary500 font-avenir"
                  >
                    {/* <ArrowRightIcon className="w-4 h-4" /> */}
                    Read All
                  </Link>
                </div>
              ) : (
                <div className="w-full">
                  <div className="flex gap-2 items-center px-4 my-6">
                    <div className="bg-primary500 text-a11y p-2 rounded-[100%] flex justify-center align-middle">
                      <BellAlertIcon className="w-4 h-4" />
                    </div>

                    <div className="text-[#74788d] font-avenir">
                      Your unread notifications will appear here
                    </div>
                  </div>
                  <hr className="my-2 text-neutral50" />
                  <Link
                    to="notifications"
                    className="w-full flex gap-3 justify-center  text-neutral500 hover:text-primary500 text-center px-4 font-avenir"
                  >
                    <ArrowRightIcon className="w-4 h-4" />
                    Read All
                  </Link>
                </div>
              )}
            </div>
          )}
        >
          <button className="cursor-pointer flex">
            <Badge
              showZero
              count={
                data?.data?.filter((it: any) => it?.is_read !== "True")
                  ?.length || null
              }
            >
              {/* <div className="rounded-full p-2.5 bg-primary500 bg-opacity-[18%]"> */}
              {<BellIcon className="w-6 stroke-2 text-primary500" />}
              {/* </div> */}
            </Badge>
          </button>
        </Dropdown>

        {/* ===================== */}
        {/* ====Avatar & Name==== */}

        <span className="hidden md:flex">
          <QuestionMarkCircleIcon
            className="w-6 stroke-2 text-primary500 cursor-pointer"
            onClick={startTourHandler}
          />
        </span>
        <Dropdown
          trigger={["click"]}
          open={dropdownOpen}
          onOpenChange={() => {
            setDropdownOpen(!dropdownOpen)
          }}
          dropdownRender={(menu) => (
            <div>
              <div className="w-full  p-4 bg-white rounded-lg drop-shadow-2xl border">
                <div className="flex flex-col w-full gap-5">
                  <div className="flex items-center gap-3">
                    {/* <div className=" ">
                      <img
                        className=" rounded-full border-[4px] border-primary50 w-9 aspect-square"
                        src={
                          user?.userAvatarFilename ??
                          "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
                        }
                        alt="User Profile"
                      />
                    </div> */}
                    <div className="flex flex-col flex-wrap w-full">
                      <P2 className="font-semibold font-avenir text-neutral500 whitespace-nowrap">
                        {user?.userFullName}
                      </P2>
                      <p className="text-neutral300 font-avenir flex-wrap  text-xs w-11/12  ">
                        {user?.userName}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-2">
                    {user?.isUserOrganisation === "True" &&
                    user?.isConsultant !== "True" ? (
                      <Link
                        to="/employee/dashboard/"
                        className="flex w-full rounded-[4px] gap-3 px-1 py-2 item text-neutral500 hover:bg-primary500 hover:text-a11y"
                        onClick={handleCloseDropdown}
                      >
                        <UserCircleIcon className="w-4 stroke-2" />
                        <P2 className=" font-avenir">Employee Profile</P2>
                      </Link>
                    ) : null}

                    <Link
                      to="/dashboard/profile"
                      className="flex w-full rounded-[4px] gap-3 px-1 py-2 item text-neutral500 hover:bg-primary500 hover:text-a11y"
                      onClick={handleCloseDropdown}
                    >
                      <UserCircleIcon className="w-4 stroke-2" />
                      <P2 className=" font-avenir">Preferences</P2>
                    </Link>
                  </div>
                </div>
                <hr className="my-2 text-neutral50" />
                {/* {isPadeDomain() === true ? (
                  <button
                    onClick={handleSwitch}
                    className="w-full rounded-[4px] flex gap-3 items-center px-1 py-2 text-neutral500 hover:bg-primary500 hover:text-a11y"
                  >
                    <ArrowTopRightOnSquareIcon className="w-4 stroke-2" />
                    <P2 className=" font-avenir">Switch to old version</P2>
                  </button>
                ) : null} */}

                {user?.lastLogin ? (
                  <div className="flex w-full gap-3 px-1 py-2 item text-neutral500">
                    <LockClosedIcon className="w-4 stroke-2" />
                    <P2 className="text-sm  font-avenir">
                      Last Login: {user?.lastLogin}{" "}
                    </P2>
                  </div>
                ) : (
                  ""
                )}

                <button
                  onClick={() => {
                    mixPanelEvent("auth-sign-out")
                    handleSignout()
                  }}
                  className="w-full rounded-[4px] flex gap-3 items-center px-1 py-2 text-neutral500 hover:bg-primary500 hover:text-a11y"
                >
                  <ArrowRightOnRectangleIcon className="w-4 stroke-2" />
                  <P2 className=" font-avenir">Log Out</P2>
                </button>
              </div>
            </div>
          )}
        >
          <div className="flex items-center gap-2 cursor-pointer">
            <div className="rounded-full border-[4px] border-primary50 w-9 h-9 md:w-[45px] md:h-[45px] flex justify-center items-center ">
              <ImageComponent
                className="object-cover rounded-full h-full w-full"
                src={
                  user?.userAvatarFilename ??
                  "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
                }
                alt="User Profile"
              />
            </div>
            <P2 className="hidden md:block font-semibold font-avenir text-neutral500">
              {user?.userFullName}
            </P2>
            <ChevronUpIcon
              className={`hidden md:block ease-in-out rotate-180 transition-all w-4 stroke-[3px] text-neutral500`}
            />
          </div>
        </Dropdown>
      </div>
      {itemToView && (
        <ViewNotificationDetail
          onClose={() => {
            !itemIsRead &&
              refetch({
                queryKey: [`/employee/notifications/select`],
              })
            setItemToView(null)
          }}
          id={itemToView}
          itemIsRead={itemIsRead}
        />
      )}
    </div>
  )
}

type SubPageType = {
  title?: string
  path: string
}

type PageType = {
  title: string
  path: string
  subPages?: SubPageType[]
}

export const TopNavigationBar: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
  startTourHandler: () => void
}> = ({ isSidebarOpen, toggleSidebar, startTourHandler }) => {
  const navigate = useNavigate()

  const pages = [
    {
      title: "Dashboard",
      path: "/dashboard",
    },
    {
      title: "Welcome",
      path: "/dashboard/company-onboarding",
    },
    {
      title: "Employees",
      path: "/dashboard/employees",
      subPages: [
        { path: "/dashboard/employees/new", title: "Add new Member" },
        {
          path: "/dashboard/employees/bulk-upload",
          title: "Bulk upload new members",
        },
        {
          path: "/dashboard/employees/bulk-update",
          title: "Bulk Update Employees",
        },
        {
          path: `/dashboard/employee/:employeeKey/requests/leave-balances`,
          title: "Employee Leave Balances",
        },
        {
          path: `/dashboard/employee/:employeeKey/*`,
          title: "Employee Profile",
        },
      ],
    },
    {
      path: "/dashboard/employee/payslips",
      title: "Compensation History",
    },
    {
      path: "/dashboard/employee/payslip/view",
      title: "Payslip",
    },
    {
      path: "/dashboard/employee/payslips/all",
      title: "Payslip",
    },

    {
      title: "Needs Attention",
      path: "/dashboard/needs-attention",
      subPages: [
        {
          path: "/dashboard/needs-attention/review-employees/:id",
          title: "Review Employees",
        },
      ],
    },

    {
      title: "Departments",
      path: "/dashboard/departments",
      subPages: [
        { path: "/dashboard/departments/new", title: "Create New Department" },
        { path: "/dashboard/departments/:department", title: "Members" },
      ],
    },
    {
      title: "Manager",
      path: "/dashboard/manager",
      subPages: [
        {
          path: "/dashboard/manager",
          title: "Edit Manager permissions",
        },
      ],
    },
    {
      title: "External Admins",
      path: "/dashboard/external-admins",
    },
    {
      title: "Offers",
      path: "/dashboard/offers",
      subPages: [
        { path: "/dashboard/offers/send-offer", title: "Send an offer letter" },
      ],
    },
    {
      title: "Confirmation",
      path: "/dashboard/confirmation",
    },
    {
      title: "Exits",
      path: "/dashboard/exits",
    },
    {
      title: "Contractor Management",
      path: "/dashboard/contractor",
      subPages: [
        { path: "/dashboard/contractor/create", title: "Contractor" },
        { path: "/dashboard/contractor/view", title: "View contractor" },
        {
          path: "/dashboard/contractor/bulk-create",
          title: "Bulk create contractor",
        },
        {
          path: "/dashboard/contractor/bulk-update",
          title: "Bulk update contractor",
        },
      ],
    },
    {
      title: "Survey",
      path: "/dashboard/survey",
      subPages: [
        { path: "/dashboard/survey/new", title: "Create Survey" },
        { path: "/dashboard/survey/template", title: "Create Survey Template" },
        { path: "/dashboard/survey/edit", title: "Finish Survey" },
        { path: "/dashboard/survey/preview", title: "Preview Survey" },
        { path: "/dashboard/survey/assign", title: "Assign Survey" },
      ],
    },
    {
      title: "KYC",
      path: "/dashboard/kyc",
    },
    {
      title: "Custom Groups",
      path: "/dashboard/custom-groups",
      subPages: [
        {
          title: "View Custom Group",
          path: "/dashboard/custom-groups/:id",
        },
        {
          title: "Create Custom Groups",
          path: "/dashboard/custom-groups/create",
        },
        {
          title: "Edit Custom Groups",
          path: "/dashboard/custom-groups/edit",
        },
      ],
    },

    {
      title: "Create Custom Groups",
      path: "/dashboard/custom-groups/create",
    },
    {
      title: "Job Titles",
      path: "/dashboard/job-titles",
    },
    {
      title: "Job Titles",
      path: "/dashboard/job-titles/create",
    },
    {
      title: "Organisation Chart",
      path: "/dashboard/org-chart",
    },
    {
      title: "Requests",
      path: "/dashboard/requests/list/choose",
      subPages: [
        {
          title: "Exits",
          path: "/dashboard/requests/list/exits",
        },
        {
          path: "/dashboard/requests/exit/bulk-upload",
          title: "Bulk Exit Requests",
        },
        {
          path: "/dashboard/requests/list/loans",
          title: "Loans",
          // @ts-ignore
          // subPages: [
          //   {
          //     path: "/dashboard/requests/loan/create",
          //     title: "Record loan request for employee",
          //   },
          // ],
        },
        {
          path: "/dashboard/requests/loan/create",
          title: "Record loan request for employee",
        },
        {
          path: "/dashboard/requests/policies/create/loans",
          title: "Create Loan Policy",
        },
        {
          path: "/dashboard/requests/policies/edit/loans",
          title: "Edit Loan Policy",
        },
        {
          path: "/dashboard/requests/loan/policy/:key",
          title: "View Policy",
        },
        //  ===============
        { path: "/dashboard/requests/list/leaves", title: "Leaves" },

        {
          path: "/dashboard/requests/leave/create",
          title: "Record leave request for employee",
        },
        {
          path: "/dashboard/requests/policies/create/leaves",
          title: "Create Leave Policy",
        },
        {
          path: "/dashboard/requests/policies/edit/leaves",
          title: "Edit Leave Policy",
        },
        {
          path: "/dashboard/requests/leave/policy/:key",
          title: "View Policy",
        },
        {
          path: "/dashboard/requests/leave/policy/:key",
          title: "View Policy",
        },
        {
          path: "/dashboard/requests/policies/paid-holidays",
          title: "Other Policies",
        },
        {
          path: "/dashboard/requests/policies/federal-holidays",
          title: "Other Policies",
        },
        //  ===============
        { path: "/dashboard/requests/list/exits", title: "Exits" },
        {
          path: "/dashboard/requests/exit/create",
          title: "Record exit request for employee",
        },
        {
          path: "/dashboard/requests/policies/create/exits",
          title: "Create Exit Policy",
        },
        {
          path: "/dashboard/requests/policies/edit/exits",
          title: "Edit Exit Policy",
        },
        {
          path: "/dashboard/requests/exit/policy/:key",
          title: "View Policy",
        },
        //  ===============
        {
          path: "/dashboard/requests/list/salary-advances",
          title: "Salary Advances",
        },
        {
          path: "/dashboard/requests/salary-advance/create",
          title: "Record salary advance request for employee",
        },
        {
          path: "/dashboard/requests/policies/create/salary-advance",
          title: "Create Salary Advance Policy",
        },
        {
          path: "/dashboard/requests/policies/edit/salary-advance",
          title: "Edit Salary Advance Policy",
        },
        {
          path: "/dashboard/requests/salary-advance/policy/:key",
          title: "View Policy",
        },
        //  ===============
        {
          path: "/dashboard/requests/list/redeployments",
          title: "Redeployments",
        },
        {
          path: "/dashboard/requests/redeployment/create",
          title: "Record redeployment request for employee",
        },
        {
          path: "/dashboard/requests/redeployment/bulk-upload",
          title: "Bulk Redeployment Requests",
        },
        {
          path: "/dashboard/requests/policies/create/redeployment",
          title: "Create Redeployment Policy",
        },
        {
          path: "/dashboard/requests/policies/edit/redeployment",
          title: "Edit Redeployment Policy",
        },
        {
          path: "/dashboard/requests/redeployment/policy/:key",
          title: "View Policy",
        },
        //  ===============
        {
          path: "/dashboard/requests/list/documents",
          title: "Documents",
        },
        {
          path: "/dashboard/requests/document/create",
          title: "Record document request for employee",
        },
        {
          path: "/dashboard/requests/policies/create/documents",
          title: "Create Document Policy",
        },
        {
          path: "/dashboard/requests/policies/edit/documents",
          title: "Edit Document Policy",
        },
        {
          path: "/dashboard/requests/document/policy/:key",
          title: "View Policy",
        },
        //  ===============
      ],
    },
    // {
    //   title: "All Requests",
    //   path: "/dashboard/requests/all/loans",
    //   subPages: [{ path: "/dashboard/requests/loans/create", title: "Loans" }],
    // },
    // {
    //   title: "All Requests",
    //   path: "/dashboard/requests/all/salary-advances",
    //   subPages: [
    //     {
    //       path: "/dashboard/requests/salary-advance/create",
    //       title: "Salary Advance",
    //     },
    //   ],
    // },
    // {
    //   title: "Request Policy",
    //   path: "/dashboard/requests/policies",
    //   subPages: [
    //     {
    //       path: "/dashboard/requests/policies/:policy/view/*",
    //       title: "View Policy",
    //     },
    //     {
    //       path: "/dashboard/requests/policies/create/*",
    //       title: "Create Policy",
    //     },
    //   ],
    // },
    {
      title: "Benefits",
      path: "/dashboard/benefits",
      subPages: [
        { path: "/dashboard/benefits/new", title: "Create new benefits" },
        {
          path: "/dashboard/benefits/edit-employees/*",
          title: "Enroll Employees",
        },
        { path: "/dashboard/benefits/medical", title: "Medicals" },
        { path: "/dashboard/benefits/pensions", title: "Pension" },
        // {
        //   path: "/dashboard/benefits/pensions/bulk-upload",
        //   title: "Bulk Enroll",
        // },
        // { path: "/dashboard/benefits/*" },
        { path: "/dashboard/benefits/*", title: "" },
      ],
    },
    {
      title: "Pension Benefits",
      path: "/dashboard/benefits/pensions",
      subPages: [
        // { path: "/dashboard/benefits/new", title: "Create new benefits" },
        {
          path: "/dashboard/benefits/pensions/bulk-upload",
          title: "Bulk Enroll",
        },
      ],
    },
    {
      title: "Hiring",
      path: "/dashboard/hiring/dashboard/",
      subPages: [
        { path: "/dashboard/hiring/post-job", title: "Post a job" },
        { path: "/dashboard/hiring/jobs", title: "All Jobs" },
        { path: "/dashboard/hiring/applicants", title: "All Applicants" },
        {
          path: "/dashboard/hiring/job/*",
          title: "View Job",
        },
        {
          path: "/dashboard/hiring/review/*",
          title: "Review Applicant",
        },
        {
          path: "/dashboard/hiring/settings",
          title: "Settings",
        },
        {
          path: "/dashboard/hiring/settings/pipeline",
          title: "Pipeline Stages",
        },
        {
          path: "/dashboard/hiring/settings/pipeline/create",
          title: "Create Pipeline",
        },
        {
          path: "/dashboard/hiring/settings/pipeline/edit",
          title: "Edit Pipeline",
        },

        {
          path: "/dashboard/hiring/settings/view-template",
          title: "View Template",
        },
        {
          path: "/dashboard/hiring/settings/create-template",
          title: "Create Template",
        },
        {
          path: "/dashboard/hiring/settings/edit-template",
          title: "Edit Template",
        },
        {
          path: "/dashboard/hiring/schedule-interview",
          title: "Schedule Interview",
        },
      ],
    },

    {
      title: "Payrolls",
      path: "/dashboard/payroll",
      subPages: [
        { path: "/dashboard/payroll/payable-types", title: "Payable Types" },
        { path: "/dashboard/payroll/payrollchange", title: "Payroll Change" },
        {
          path: "/dashboard/payroll/payrollchange/new",
          title: "Payroll Change ",
        },
        {
          path: "/dashboard/payroll/change/bulkupload",
          title: "Bulk Payroll Change ",
        },
        { path: "/dashboard/payroll/payrollrecord", title: "Payroll Record" },
        { path: "/dashboard/payroll/payrollrecord/view", title: "View" },
        { path: "/dashboard/payroll/payrollskip", title: "Skip Employees" },
        {
          path: "/dashboard/payroll/payrollskip/bulkupload",
          title: "Bulk Upload Exit",
        },
        {
          path: "/dashboard/payroll/configuration/*",
          title: "Configuration",
        },
        { path: "/dashboard/payroll/*" },
      ],
    },
    {
      title: "Claims",
      path: "/dashboard/claims",
    },
    {
      title: "Payrolls",
      path: "/dashboard/payroll",
    },
    {
      title: "Payment Schedules",
      path: "#",
      subPages: [
        {
          path: "/dashboard/payment-schedules/regular",
          title: "Regular Payment Schedule",
        },
        {
          path: "/dashboard/payment-schedules/view-regular-payment",
          title: "Regular  Schedule",
        },
        {
          path: "/dashboard/payment-schedules/custom",
          title: "Custom Payment Schedule",
        },
        {
          path: "/dashboard/payment-schedules/reversals",
          title: "Reversals",
        },
        {
          path: "/dashboard/payment-schedules/custom/new",
          title: "Create Schedule",
        },
        {
          path: "/dashboard/payment-schedules/custom/employees",
          title: "Create Employee Payment Schedule",
        },
        {
          path: "/dashboard/payment-schedules/custom/contractors",
          title: "Create Contractor Payment Schedule",
        },
        {
          path: "/dashboard/payment-schedules/custom/edit",
          title: "Edit Custom Payment Schedule",
        },
        {
          path: "/dashboard/payment-schedules/custom/upload",
          title: "Bulk upload",
        },
        {
          path: "/dashboard/payment-schedules/view-payment",
          title: "View Payment",
        },
        {
          path: "/dashboard/payment-schedules/preview-payment",
          title: "Preview Payment",
        },
        {
          path: "/dashboard/payment-schedules/statustory",
          title: "View Statutory Payment",
        },
        {
          path: "/dashboard/payment-schedules/view-tax-payment/report",
          title: "View Tax Report",
        },
        {
          path: "/dashboard/payment-schedules/view-pension-payment/report",
          title: "View Pension Report",
        },
        {
          path: "/dashboard/payment-schedules/view-nhf-payment/report",
          title: "View NHF Report",
        },
        {
          path: "/dashboard/payment-schedules/view-nsitf-payment/report",
          title: "View NSITF Report",
        },
      ],
    },

    {
      title: "Wallets",
      path: "#",
      subPages: [
        { path: "/dashboard/wallets/overview", title: "Overview" },
        { path: "/dashboard/wallets/direct-debit", title: "Direct Debit" },
        { path: "/dashboard/wallets/statements", title: "Transactions" },
        { path: "/dashboard/wallets/integration", title: "Integration" },
        {
          path: "/dashboard/wallets/integration/company-verification",
          title: "Update Kyc",
        },
        {
          path: "/dashboard/wallets/transfers",
          title: "Transfers",
        },

        // CROSS BORDER
        {
          path: "/dashboard/wallets/cross-border/overview",
          title: "Wallet Balances",
        },
        {
          path: "/dashboard/wallets/cross-border/view",
          title: "View Currency",
        },
        {
          path: "/dashboard/wallets/cross-border/view/transactions",
          title: "Transactions",
        },
        {
          path: "/dashboard/wallets/cross-border/swap-money",
          title: "Swap",
        },
        {
          path: "/dashboard/wallets/cross-border/send-money",
          title: "Send Money",
        },
        {
          path: "/dashboard/wallets/cross-border/move-funds",
          title: "Move Funds",
        },
      ],
    },

    {
      title: "Earned wage access",
      path: "#",

      subPages: [
        { path: "/dashboard/ewa/withdrawals", title: "Withdrawal History" },
        {
          path: "/dashboard/ewa/employees",
          title: "Employees",
        },
        { path: "/dashboard/ewa/invoice", title: "Invoice" },
        { path: "/dashboard/ewa/analytics", title: "Analytics" },
      ],
    },
    {
      title: "Tasks",
      path: "/dashboard/tasks",
      subPages: [
        { path: "/dashboard/tasks/onboarding" },
        { path: "/dashboard/tasks/new", title: "Create New Task" },
        { path: "/dashboard/tasks/view", title: "Edit Task" },
      ],
    },
    {
      title: "Performance",
      path: "#",
      // path: "/dashboard/performance/reviews",
      subPages: [
        { path: "/dashboard/performance/performance-period", title: "Periods" },
        {
          path: "/dashboard/performance/performance-objectives",
          title: "Objectives",
        },
        {
          path: "/dashboard/performance/groups",
          title: "Groups",
        },
        {
          path: "/dashboard/performance/reviews",
          title: "Reviews",
        },
        {
          path: "/dashboard/performance/reviews/round/view",
          title: "View Review",
        },
        {
          path: "/dashboard/performance/reviews/round/view-employee",
          title: "View Reviewees",
        },
        {
          path: "/dashboard/performance/reviews/template",
          title: "Create Review Template",
        },
        {
          path: "/dashboard/performance/reviews/create",
          title: "Create Review",
        },
        {
          path: "/dashboard/performance/reviews/edit",
          title: "Edit Review",
        },
        {
          path: "/dashboard/performance/reviews/reports",
          title: "Review Report",
        },
        {
          path: "/dashboard/performance/ratings",
          title: "Ratings",
        },
        {
          path: "/dashboard/performance/groups/view-group",
          title: "View Group",
        },
        {
          path: "/dashboard/performance/groups/new",
          title: "Create Group",
        },
        {
          path: "/dashboard/performance/groups/edit",
          title: "Edit Group",
        },
      ],
    },

    {
      title: "Feedback",
      path: "/dashboard/performance/feedback",
    },
    {
      title: "Conversations",
      path: "/dashboard/performance/conversations",
      subPages: [
        {
          path: "/dashboard/performance/add-prompt",
          title: "Add Prompt",
        },
      ],
    },
    {
      title: "Reviews",
      path: "/dashboard/reviews",
    },
    {
      title: "Disciplinary",
      path: "/dashboard/disciplinary",
    },
    {
      title: "Documents",
      path: "/dashboard/documents",
      subPages: [
        { path: "/dashboard/documents/new", title: "Create new document" },
        {
          path: "/dashboard/documents/offer-template",
          title: "Offer Template",
        },

        {
          path: "/dashboard/documents/upload",
          title: "Create Employee Document",
        },
        {
          path: "/dashboard/documents/view-employee-document",
          title: "View Employee Document",
        },
        {
          path: "/dashboard/documents/offer-template/view",
          title: "View Offer Document",
        },
      ],
    },

    {
      title: "Reports",
      path: "/dashboard/report",
      subPages: [
        { path: "/dashboard/report/payroll-journal", title: "Payroll Journal" },

        {
          title: "Payroll-Summary",
          path: "/dashboard/report/payroll-summary",
        },
        {
          title: "Payroll-Variance",
          path: "/dashboard/report/payroll-variance",
        },
        {
          title: "Year-to-Date",
          path: "/dashboard/report/year-to-date",
        },
        {
          title: "Statustory-Benefits",
          path: "/dashboard/report/statustory-benefits",
        },
        {
          title: "Custom-Benefits",
          path: "/dashboard/report/custom-benefits",
        },
        {
          title: "Payroll-Change",
          path: "/dashboard/report/payroll-change",
        },
        {
          title: "Payroll-Exemption",
          path: "/dashboard/report/payroll-exemption",
        },
        {
          title: "Paygrade-Summary",
          path: "/dashboard/report/paygrade-summary",
        },
        {
          title: "Employee-Request",
          path: "/dashboard/report/employee-request",
        },
        {
          title: "Leave-Balances",
          path: "/dashboard/report/leave-balances",
        },
        {
          title: "Bank-Transactions",
          path: "/dashboard/report/bank-transactions",
        },
        {
          title: "Statutory-Payments",
          path: "/dashboard/report/statutory-payments",
        },
        {
          title: "Custom Fields",
          path: "/dashboard/report-preview/custom-fields",
        },
        {
          title: "Employee Summary",
          path: "/dashboard/report-preview/employee-summary",
        },
        {
          title: "Employee Report Builder",
          path: "/dashboard/report/employee-report-builder",
        },
      ],
    },

    { title: "Custom Report", path: "/dashboard/report/custom-report" },
    { path: "/dashboard/reports-payroll-history", title: "Payroll History" },
    {
      path: "/dashboard/reports-payroll-history/view",
      title: "View Payroll History",
    },
    {
      path: "/dashboard/reports/payroll-receipt",
      title: "Payroll Receipt",
    },
    ////  ASSETS
    {
      title: "Assets",
      path: "/dashboard/assets",
      subPages: [
        { path: "/dashboard/assets/new", title: "Create new asset" },
        { path: "/dashboard/assets/edit/*", title: "Edit asset" },
        { path: "/dashboard/assets/delete/*", title: "Delete asset" },
        { path: "/dashboard/assets/view/*", title: "View asset" },
        {
          path: "/dashboard/assets/:assetid/enroll-people/*",
          title: "Remove or add employees",
        },
      ],
    },
    {
      title: "Assignments",
      path: "/dashboard/assignments",
      subPages: [
        { path: "/dashboard/assignments/new", title: "Assign Assets" },
        { path: "/dashboard/assignments/view/*", title: "View Asset" },
        { path: "/dashboard/assignments/edit/*", title: "Edit Asset Details" },
        { path: "/dashboard/assignments/employee/*", title: "Employee Asset" },
      ],
    },
    {
      title: "Company details",
      path: "/dashboard/company-details",
      subPages: [
        {
          path: "/dashboard/company-details/new",
          title: "New linked account",
        },
        {
          path: "/dashboard/company-details/edit-info",
          title: "Edit company details",
        },
        {
          path: "/dashboard/company-details/add-workplace",
          title: "Add workplace",
        },
        {
          path: "/dashboard/company-details/manage-workplace",
          title: "Manage workplace",
        },
        {
          path: "/dashboard/company-details/edit-workplace/:id",
          title: "Edit workplace",
        },
      ],
    },

    {
      title: "Time Tracking",
      path: "/dashboard/time-tracking",
      subPages: [],
    },
    {
      title: "Absence Batches",
      path: "/dashboard/time-tracking/absence",
      subPages: [
        { path: "dashboard/time-tracking/absence/:id", title: "Absence" },
        {
          path: "/dashboard/time-tracking/absence/:id/record-absence",
          title: "Record Employee Absence",
        },
        {
          path: "/dashboard/time-tracking/absence/:id/bulk-upload-absent-days",
          title: "Bulk Upload Absent Days",
        },
      ],
    },
    {
      title: "Help",
      path: "/dashboard/help",
    },
    {
      title: "Support",
      path: "/dashboard/support",
    },
    {
      title: "Admins",
      path: "/dashboard/settings/permissions/admins",
      subPages: [
        {
          path: "/dashboard/settings/permissions/admins",
          title: "All Admins",
        },
        {
          path: "/dashboard/settings/permissions/admins/new",
          title: "Add Admin",
        },
        {
          path: "/dashboard/settings/permissions/admins/edit/*",
          title: "Edit Admin",
        },
        // {
        //   path: "/dashboard/settings/permissions/manager",
        //   title: "Edit Manager permissions",
        // },
      ],
    },
    {
      title: "HR & Payroll",
      path: "/dashboard/settings/hr-and-payroll",
      subPages: [
        {
          path: "/dashboard/settings/hr-and-payroll/pay-schedules/create",
          title: "Create custom pay schedule",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/pay-schedules",
          title: "Pay schedules",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/pay-schedules/edit",
          title: "Edit pay schedules",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/pay-schedules/edit-custom",
          title: "Edit pay schedules",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/paygrades/create",
          title: "Create new Paygrade",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/paygrades/*",
          title: "Paygrade",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/allowances/create",
          title: "Create new allowances",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/allowances/*",
          title: "Paygrade allowances",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/statutory-benefits/create",
          title: "Create new Statutory Benefits",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/statutory-benefits/*",
          title: "Statutory Benefits",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/tax-tables",
          title: "Tax table",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/job-titles/create",
          title: "Create new Job title",
        },
        {
          path: "/dashboard/settings/hr-and-payroll/job-titles/*",
          title: "Job Title",
        },

        {
          path: "/dashboard/settings/hr-and-payroll/*",
          // title: "Add or Edit Admin",
        },
      ],
    },
    {
      title: "Roles and Permissions",
      path: "/dashboard/settings/roles-permissions",
      subPages: [
        {
          path: "/dashboard/settings/roles-permissions/custom-roles/create",
          title: "Create Custom Role",
        },
        {
          path: "/dashboard/settings/roles-permissions/view/*",
          title: "View Role",
        },
        {
          path: "/dashboard/settings/roles-permissions/edit/*",
          title: "Edit Role",
        },
        {
          path: "/dashboard/settings/roles-permissions/create/*",
          title: "Create Role",
        },
      ],
    },
    {
      title: "Suspensions",
      path: "/dashboard/disciplinary/suspensions",
      // subPages: [
      //   {
      //     path: "/dashboard/disciplinary/suspensions",
      //     title: "Record employee violation",
      //   },
      // ],
    },
    {
      title: "Violations",
      path: "/dashboard/disciplinary/violations",
      subPages: [
        {
          path: "/dashboard/disciplinary/violations/create",
          title: "Record employee violation",
        },
      ],
    },
    {
      title: "Policies",
      path: "/dashboard/disciplinary/policies",
      subPages: [
        {
          path: "/dashboard/disciplinary/policies/new",
          title: "Record new policy",
        },
        {
          path: "/dashboard/disciplinary/policies/update",
          title: "Update policy",
        },
        {
          path: "/dashboard/disciplinary/policies/view/*",
          title: "View policy",
        },
        {
          path: "/dashboard/disciplinary/policies/*",
        },
      ],
    },
    {
      title: "Triggers",
      path: "/dashboard/disciplinary/triggers",
      subPages: [
        {
          path: "/dashboard/disciplinary/triggers/*",
        },
      ],
    },
    {
      title: "Dashboard",
      path: "/dashboard",
      subPages: [
        {
          path: "/dashboard/notifications",
          title: "Notifications",
        },
      ],
    },
    {
      title: "Announcements",
      path: "/dashboard/settings/announcements",
      subPages: [
        {
          path: "/dashboard/settings/announcements",
          title: "Announcements",
        },
        {
          path: "/dashboard/settings/announcements/new",
          title: "Create announcements",
        },
        {
          path: "/dashboard/settings/announcements/edit/*",
          title: "Edit announcement",
        },
        {
          path: `/dashboard/settings/announcements/view/*`,
          title: "View announcement",
        },
      ],
    },
    {
      title: "Announcement Groups",
      path: "/dashboard/settings/announcement_groups",
      subPages: [
        {
          path: "/dashboard/settings/announcement_groups",
          title: "Announcement groups",
        },
        {
          path: "/dashboard/settings/announcement-group/new",
          title: "Create announcement group",
        },
      ],
    },
    {
      title: "Violation types",
      path: "/dashboard/disciplinary/violation-types",
      subPages: [
        {
          path: "/dashboard/disciplinary/violation-types/*",
        },
      ],
    },
    {
      title: "Integration",
      path: "/dashboard/settings/integration",
      subPages: [
        {
          path: "/dashboard/settings/integration/company-verification",
          title: "Company Verification",
        },
      ],
    },
    {
      title: "Integrations",
      path: "/dashboard/settings/integrations",
      subPages: [
        {
          path: "/dashboard/settings/integrations/oracle/configurations",
          title: "Oracle",
        },
        {
          path: "/dashboard/settings/integrations/zoho/configurations",
          title: "Zoho",
        },
      ],
    },
    {
      title: "Single Sign-On",
      path: "/dashboard/settings/sso",
    },
    {
      title: "Customization",
      path: "/dashboard/settings/customization",
      subPages: [
        {
          path: "/dashboard/settings/customization/custom-fields",
          title: "Manage custom fields",
        },
        {
          path: "/dashboard/settings/customization/custom-fields/groups",
          title: "Manage custom fields",
        },
        {
          path: "/dashboard/settings/customization/custom-fields/bulk-upload",
          title: "Bulk Upload Fields",
        },
      ],
    },
    {
      title: "Plan & Billing",
      path: "/dashboard/settings/plan-and-billing",
      subPages: [
        {
          path: "/dashboard/settings/plan-and-billing/invoices",
          title: "See Invoices",
        },
        {
          path: "/dashboard/settings/plan-and-billing/plans",
          title: "PaidHR Plans",
        },
        {
          path: "/dashboard/settings/plan-and-billing/*",
        },
      ],
    },
    {
      title: "Custom Notifications",
      path: "/dashboard/settings/approval-workflow/custom-notifications",
      subPages: [
        {
          path: "/dashboard/settings/approval-workflow/custom-notifications",
          title: "Manage Custom notifications",
        },
        {
          path: "/dashboard/settings/approval-workflow/custom-notifications/edit/*",
          title: "Edit custom notification",
        },
        {
          path: "/dashboard/settings/approval-workflow/custom-notifications/new",
          title: "Create custom notification",
        },
      ],
    },
    {
      title: "Approval Workflow",
      path: "/dashboard/settings/approval-workflow",
      subPages: [
        {
          path: "/dashboard/settings/approval-workflow/custom-roles",
          title: "Custom Roles",
        },
        {
          path: "/dashboard/settings/approval-workflow/custom-roles/new",
          title: "Create Custom role",
        },
        {
          path: "/dashboard/settings/approval-workflow/custom-notifications",
          title: "Manage Custom notifications",
        },
        {
          path: "/dashboard/settings/approval-workflow/custom-notifications/edit/*",
          title: "Edit custom notification",
        },
        {
          path: "/dashboard/settings/approval-workflow/custom-notifications/new",
          title: "Create custom notification",
        },
        {
          path: "/dashboard/settings/approval-workflow/*",
          title: "Approval Workflow",
        },
      ],
    },
    {
      title: "Audit",
      path: "/dashboard/audits",
    },
    {
      title: "Calendar",
      path: "/dashboard/calendar",
    },
  ]

  const { pathname } = useLocation()
  const [currentPage, setCurrentPage] = useState<PageType>()
  const [currentSubPage, setCurrentSubPage] = useState<SubPageType>()
  const [isSubPage, toggleIsSubPage] = useState<boolean>(false)

  // find current page & subpage
  useEffect(() => {
    return throttle(() => {
      let page = pages.find((page) => page.path === pathname)
      setCurrentPage(page)

      const subPages = pages
        .map((page) => page.subPages!)
        .filter(Boolean)
        .reduce((a, e) => [...a, ...e], [])

      const subPage = subPages.find(
        (page) => matchRoutes([page], pathname) !== null
      )!

      if (page === undefined && subPage !== undefined) {
        setCurrentSubPage(subPage)
        toggleIsSubPage(true)

        page = pages.find((page) => {
          return (
            page.subPages !== undefined &&
            page.subPages.length > 0 &&
            page.subPages.find((page) => page.path === subPage.path)
          )
        })
        setCurrentPage(page)
      } else {
        setCurrentSubPage(undefined)
        toggleIsSubPage(false)
      }
    }, 200)
  }, [pages, pathname])

  return (
    <div className=" flex items-center justify-between w-full h-full px-4 md:px-6 lg:px-10 mx-auto ">
      {/* ========================= */}
      {/* ====Logo===== */}
      <div className="flex gap-4 md:hidden ">
        {isPadeDomain() ? (
          <img
            src={
              "https://padefiles.blob.core.windows.net/images/44470bf9-a203-4d7b-a5f7-f5c20f6b87a0.svg "
            }
            alt="PaidHR Logo"
            className=""
            width={30}
            height={30}
          />
        ) : // <img
        //   src={customizationSettings?.brand?.menuLogoUrl}
        //   alt=""
        //   className="w-[100px]"
        // />
        null}

        {/* ========================= */}

        {/* ====Menu Drawer Btn===== */}

        <button
          className=" w-[27px] "
          type="button"
          onClick={() => {
            toggleSidebar(!isSidebarOpen)
            document.body.classList.add("overflow-hidden")
          }}
        >
          <Bars3Icon className="w-6 h-6" />
        </button>
      </div>

      <div className="flex gap-4">
        {isSubPage ? (
          <button
            className="hidden md:flex items-center justify-center"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="w-4 h-4 text-neutral800 stroke-2" />
          </button>
        ) : null}
        <div className="hidden md:flex items-center gap-2">
          {currentPage !== undefined ? (
            isSubPage === true ? (
              currentSubPage?.title === undefined ||
              currentSubPage.title.length === 0 ? (
                <TextLink
                  to={currentPage.path}
                  title={currentPage.title}
                  className="text-base font-semibold no-underline text-neutral700 hover:text-primary500 font-circular"
                >
                  {currentPage.title}
                </TextLink>
              ) : (
                <>
                  <TextLink
                    to={currentPage.path}
                    title={currentPage.title}
                    className="text-base font-semibold no-underline text-neutral700 hover:text-primary500 font-circular"
                  >
                    {currentPage.title}
                  </TextLink>
                  <ChevronRightIcon className="w-3.5 stroke-2 text-neutral900" />
                  <p className="text-base font-semibold  font-circular text-neutral100">
                    {currentSubPage?.title!}
                  </p>
                </>
              )
            ) : (
              <p className="text-base font-semibold font-circular text-neutral700">
                {currentPage?.title}
              </p>
            )
          ) : (
            <></>
          )}
        </div>
      </div>

      <UserProfile
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        startTourHandler={startTourHandler}
      />
    </div>
  )
}
